import React from "react";
import { Flex, FormLabel, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { formatCurrency } from "../../../../lib/utilities";
import { HSeparator } from "../../../../components/separator/Separator";

interface Props {
  paymentAmount: number;
  transactionFee: number;
  totalRepay: number;
  balanceToPayNow: number;
  dueDate: string;
  currency: string;
  lateFeeAmount: number;
}

export const RepaymentTerms: React.FC<Props> = ({
  paymentAmount,
  balanceToPayNow,
  transactionFee,
  totalRepay,
  currency,
  dueDate,
  lateFeeAmount,
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} w={["100%", "100%", "100%", "130%"]}>
      <Flex justify="space-between" mb={1}>
        <FormLabel>{t("amount")}</FormLabel>
        <Text>
          {currency} {formatCurrency(paymentAmount)}
        </Text>
      </Flex>
      <Flex justify="space-between" mb={1}>
        <FormLabel>{t("transaction_fee")}</FormLabel>
        <Text flex="1" textAlign="right">
          {currency} {formatCurrency(transactionFee)}
        </Text>
      </Flex>
      {lateFeeAmount > 0 && (
        <Flex justify="space-between" mb={1}>
          <FormLabel fontWeight="bold">Late fee</FormLabel>
          <Text fontWeight="bold">
            {currency} {formatCurrency(lateFeeAmount)}
          </Text>
        </Flex>
      )}
      <Flex justify="space-between" mb={1}>
        <FormLabel fontWeight="bold">{t("total_amount_to_repay")}</FormLabel>
        <Text fontWeight="bold">
          {currency} {formatCurrency(totalRepay + lateFeeAmount)}
        </Text>
      </Flex>
      <HSeparator mb="5px" mt="0px" />
      <Flex justify="space-between">
        <FormLabel>{t("due_date")}</FormLabel>
        <Text>{dueDate}</Text>
      </Flex>
      <HSeparator mb="5px" mt="5px" />
      <Flex justify="space-between">
        <FormLabel fontWeight="bold">{t("balance_to_pay_now")}</FormLabel>
        <Text fontWeight="bold">
          {currency} {formatCurrency(balanceToPayNow)}
        </Text>
      </Flex>
    </Stack>
  );
};
